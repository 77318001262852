import React from 'react'

const YearDropDown = () => {
  const startYear = 2020;
  const currentYear = new Date().getFullYear() + 1 // Always auto add next year to dropdown list
  let options =[]
  
  for(let i = startYear; i<= currentYear; i++){
    options.push(i)
  }

  if(options.length){
    return(
        <>
            {options?.map((item)=>{
                return <option value={item}>{item-1}-{item}</option>

            })}
        </>
    )
  }
}

export default YearDropDown