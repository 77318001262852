import React, { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
  getChartsOverallResults,
  getChartsOverallByAssessmentResults,
  getChartsOverallProgression,
  loadSchoolsList,
} from "../../../actions/admin";
import Loading from "../../layouts/Loading";
import YearDropDown from '../../layouts/YearDropDown';
import OverallResultChart from "./OverallResultChart";
import OverallResultsByAssessmentType from "./OverallResultsByAssessmentChart";
import OverallResultsProgression from "./OverallResultsProgressionChart";
import { set } from "lodash";
import Toogle from "../../layouts/Toogle";

// your forceUpdate hook
const useForceUpdate = () => {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
};
const CompareDashboard = ({
  getChartsOverallProgression,
  getChartsOverallResults,
  getChartsOverallByAssessmentResults,
  overallResultsCharts,
  overallResultsByAssessmentCharts,
  overallResultsProgressionCharts,
  overallResultsChartsByAssessmentLoading,
  overallResultsChartsLoading,
  overallResultsChartsProgressionLoading,
  user,
  school,
  loadSchoolsList,
}) => {
  const hist = useHistory();
  const forceUpdate = useForceUpdate();
  const [benchMark, setBenchMark] = useState([-1]);
  const [year, setYear] = useState(2021);
  const [years, setYears] = useState([]);
  const [grade, setGrade] = useState(-1);
  const [classroom, setClassroom] = useState(-1);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  // Load school list related to the user
  useEffect(() => {
    if (user.UserTypeID === 5) {
      loadSchoolsList();
    } else if (user.UserTypeID === 6) {
      loadSchoolsList({ schoolIds: user.schoolIds });
    }
  }, [user, year, benchMark, school, grade, classroom, checked]);

  useEffect(() => {
    const loadData = async () => {
      if (years.length > 0) {
        setLoading(true);
        await getChartsOverallResults(
          years,
          benchMark,
          school || -1,
          grade || -1,
          classroom || -1,
          -1,
          -1,
          user.schoolIds || -1
        );
        await getChartsOverallByAssessmentResults(
          years,
          benchMark,
          school || -1,
          grade || -1,
          classroom || -1,
          -1,
          -1,
          user.schoolIds || -1
        );
        await getChartsOverallProgression(
          years,
          benchMark,
          school || -1,
          grade || -1,
          classroom || -1,
          -1,
          -1,
          user.schoolIds || -1
        );
        setLoading(false);
      }
    };
    loadData();
  }, [
    years,
    benchMark,
    school,
    grade,
    classroom,
    user,
    getChartsOverallResults,
    getChartsOverallByAssessmentResults,
    getChartsOverallProgression,
  ]);

  return (
    <Fragment>
      {!user && loading ? (
        <div className="d-flex align-items-center h-100 justify-content-center w-100">
          <Loading />
        </div>
      ) : (
        <div className="p-sm-5 p-2 w-100 dashboard-margin">
          <div className="mb-3 ">
            <h6 className="txt-primary-light">
              {user.UserType} / Compare Dashboard
            </h6>
            <div className="d-sm-flex  w-100 align-items-center justify-content-between">
              <div className="d-flex mb-2 mb-sm-0">
                <div
                  className="admin-back mx-2  rounded-circle d-flex align-items-center justify-content-center txt-primary"
                  onClick={() => {
                    hist.goBack();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="currentColor"
                    className="bi bi-arrow-left-short"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                    />
                  </svg>
                </div>
                <h4 className="m-0">View</h4>
              </div>
              <div className="d-flex w-100 align-items-center justify-content-end ">
                {/* Toogle */}
                <div
                  className="d-flex p-0 mx-2 align-items-center"
                  style={{ minWidth: "200px" }}
                >
                  <Toogle
                    checked={checked}
                    setChecked={setChecked}
                    labels={["Percentile", "Benchmark"]}
                  />
                </div>
                <div className="d-flex p-0 my-3">
                  <div
                    className="btn button-parent button-primary-outline txt-primary d-flex align-items-center px-3 "
                    onClick={() => {
                      setYears([]);
                    }}
                  >
                    Clear
                  </div>
                  <select
                    className="form-select form-control bg-primary text-white mx-2"
                    aria-label="Default select example"
                    value={year}
                    id="year"
                    onChange={(e) => setYear(parseInt(e.target.value))}
                  >
                    <option value={0}>Select a year</option>
                    <YearDropDown />
                  </select>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  class="bi bi-plus add-item-btn rounded-circle border border-primary txt-primary border-2 "
                  viewBox="0 0 16 16"
                  onClick={() => {
                    if (year !== 0) {
                      let set = new Set([...years, year]);
                      if (set.size !== years.length) {
                        setYears([...years, year]);
                        setBenchMark([...benchMark, -1]);
                        forceUpdate();
                      }
                      setYear(0);
                      forceUpdate();
                    }
                  }}
                >
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
              </div>
            </div>
            {years.map((y, id) => (
              <div key={id}>
                <div className="d-flex w-100 align-items-center justify-content-between my-2 ">
                  <div className="col-5 col-lg-2 p-0">
                    <h6 className="txt-primary">
                      {y - 1}-{y}
                    </h6>
                  </div>
                  <div className="col-5 col-lg-2 p-0">
                    <select
                      className="form-select form-control bg-primary text-white m-0"
                      aria-label="Default select example"
                      value={benchMark[id]}
                      id="benchMark"
                      onChange={(e) => {
                        let temp = [...benchMark];
                        temp[id] = parseInt(e.target.value);
                        setBenchMark(temp);
                        forceUpdate();
                      }}
                    >
                      <option value={-1}>All Benchmarks</option>
                      <option value={1}>Benchmark 1 - Fall</option>
                      <option value={2}>Benchmark 2 - Winter</option>
                      <option value={3}>Benchmark 3 - Spring</option>
                    </select>
                  </div>
                </div>

                {/*  Chart area */}
                <div className="d-flex justify-content-between align-items-center flex-column flex-lg-row row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-4">
                        {overallResultsCharts[id] && (
                          <OverallResultChart
                            chartData={
                              overallResultsCharts[id][checked ? 1 : 0]
                            }
                            calculationType={checked ? 1 : 0} //1: Risk, 0: Percentile
                            benchMarkID={benchMark}
                          />
                        )}
                      </div>

                      <div className="col-8">
                        {overallResultsByAssessmentCharts[id] && (
                          <OverallResultsByAssessmentType
                            chartData={
                              overallResultsByAssessmentCharts[id][
                                checked ? 1 : 0
                              ]
                            } //1: Risk, 0: Percentile
                            NumberOfStudents={
                              overallResultsByAssessmentCharts[id][2]?.length > 0
                                ? overallResultsByAssessmentCharts[id][2][0]
                                    ?.NumberOfStudent
                                : ""
                            }
                            calculationType={checked ? 1 : 0} //1: Risk, 0: Percentile
                            benchMarkID={benchMark}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      {overallResultsProgressionCharts[id] && (
                        <OverallResultsProgression
                          chartData={
                            overallResultsProgressionCharts[id][checked ? 1 : 0]
                          } //1: Risk, 0: Percentile
                          NumberOfStudents={
                            overallResultsProgressionCharts[id][2]?.length > 0
                              ? overallResultsProgressionCharts[id][2][0]
                                  ?.NumberOfStudent
                              : ""
                          }
                          calculationType={checked ? 1 : 0} //1: Risk, 0: Percentile
                          benchMarkID={benchMark}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Fragment>
  );
};
CompareDashboard.propTypes = {
  user: PropTypes.object,
  reports: PropTypes.array,
  reportsLoading: PropTypes.bool,
  generateReport: PropTypes.func.isRequired,
  loadSchoolsList: PropTypes.func.isRequired,
  getChartsOverallByAssessmentResults: PropTypes.func.isRequired,
  getChartsOverallResults: PropTypes.func.isRequired,
  getChartsOverallProgression: PropTypes.func.isRequired,
  overallResultsChartsByAssessmentLoading: PropTypes.bool,
  overallResultsChartsLoading: PropTypes.bool,
  overallResultsChartsProgressionLoading: PropTypes.bool,
  overallResulsChartsLoading: PropTypes.bool,
  overallResultsCharts: PropTypes.array,
  clearReport: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  overallResultsByAssessmentCharts:
    state.admin.overallResultsByAssessmentCharts,
  overallResultsChartsByAssessmentLoading:
    state.admin.overallResultsChartsByAssessmentLoading,
  overallResultsProgressionCharts: state.admin.overallResultsProgressionCharts,
  overallResultsChartsProgressionLoading:
    state.admin.overallResultsChartsProgressionLoading,
  overallResultsCharts: state.admin.overallResultsCharts,
  overallResultsChartsLoading: state.admin.overallResultsChartsLoading,
  user: state.auth.user,
  schoolsList: state.admin.schoolsList,
  schoolListLoading: state.admin.schoolListLoading,
  school: state.admin.school,
  schoolLoading: state.admin.schoolLoading,
  classroomsList: state.admin.classroomsList,
  classroomsListLoading: state.admin.classroomsListLoading,
});

export default connect(mapStateToProps, {
  getChartsOverallByAssessmentResults,
  getChartsOverallProgression,
  getChartsOverallResults,
  loadSchoolsList,
})(CompareDashboard);
